<template>
  <div class="image">
    <b-link :id="`carousel-delete-image-${image.id}`" class="delete-image">
      <i class="far fa-trash"></i>
    </b-link>
    <b-popover
      :target="`carousel-delete-image-${image.id}`"
      triggers="click"
      :show.sync="popoverShow"
      placement="top"
    >
      <p>{{ trans('prospects.landingpage.are_you_sure') }}</p>
      <div>
        <b-button size="sm" class="mr-2" variant="success" @click="deleteImage">
          {{ trans('global.delete') }}
        </b-button>
        <b-button size="sm" variant="light" @click="popoverShow = !popoverShow">
          {{ trans('global.abort') }}
        </b-button>
      </div>
    </b-popover>

    <div v-if="imgURL" class="imgwrp" :style="`background-image: url('${imgURL}?fit=crop&w=150&h=150')`"></div>
    <div v-else class="imgwrp no-image">
      <div v-if="progress" class="progress">
        <RadialProgressBar
          :diameter="60"
          :completed-steps="50"
          :total-steps="100"
          :stroke-width="6"
          inner-stroke-color="#eaeaea"
          :animate-speed="200"
        >
          <p>{{ Math.round(progress) }}</p>
        </RadialProgressBar>
      </div>
    </div>
  </div>
</template>

<script>
  import RadialProgressBar from '../../../../../../Core/RadialProgressBar'
  const fb = require('../../../../../../../firebaseConfig')

  export default {
    name: 'DraggableImage',
    components: {
      RadialProgressBar
    },
    props: {
      progress: {
        type: [Boolean, Number],
        default: false
      },
      image: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      block: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        popoverShow: false
      }
    },
    computed: {
      imgURL () {
        return this.image.file !== undefined ? process.env.VUE_APP_IMAGE_CDN_URL + '/' + this.image.path + '/' + this.image.file : false
      }
    },
    methods: {
      deleteImage () {
        const self = this
        this.popoverShow = false

        fb.prospectsCollection.doc(this.prospect._id).update({
          [`landingpage.blocks.${this.block.key}.images.${this.image.id}.deleted`]: true
        }).then(() => {
          self.$bvToast.toast(self.trans('prospects.landingpage.carousel_image_deletion_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.landingpage.carousel_image_deletion_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          console.log(err)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .image {
    position: relative;
    flex: 0 1 150px;
    height: 150px;
    margin: 20px;
    background: #eaeaea;

    &.imageghost {
      border: 1px solid lighten(#67C18D, 10%);
      background: lighten(#67C18D, 30%);

      .imgwrp {
        background-image: none !important;
      }
    }

    .imgwrp {
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;

      &.no-image {
        background-color: #ffc373;
      }

      .progress {
        align-items: center;
        align-content: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        background-color: #ffb757;

        .radial-progress-container {
          flex: 0 0 60px;
          height: 60px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    a.delete-image {
      position: absolute;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background: #EC303D;
      border-radius: 0 0 0 4px;
      z-index: 2;
      color: #fff;
      font-size: 14px;

      i {
        flex: 0 0 auto;
      }

      &:hover {
        background: darken(#EC303D, 20%);
      }
    }
  }

  .popover {
    background: #EC303D;
    border: 1px solid #EC303D;
    box-shadow: 0 2px 2px rgba(0,0,0, 0.2);

    p {
      font-weight: 500;
      color: #fff;
      margin:0;
    }
  }
</style>

<style lang="scss">
  .popover-body {
    .btn-success {
      color: #e1ffde;
    }
  }

  .bs-popover-top {
    > .arrow {
      &:after {
        border-top-color: #EC303D;
      }
    }
  }
</style>
